import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

type Props = {
	text: string;
	link: string;
};

const LinkTo: React.FC<Props> = ({text, link}) => {
	const linkClasses = () =>
		classNames({
			[classes.link]: true,
			text,
		});

	return (
		<a href={link} className={linkClasses()}>
			{text}
		</a>
	);
};

export default LinkTo;
