import React from 'react';

import {ReactComponent as CheckMark} from 'assets/svg/checkMark.svg';

import classes from './styles.module.scss';

type Props = {
	text: string;
};

const Option: React.FC<Props> = ({text}) => {
	return (
		<div className={classes.wrapper}>
			<div>
				<CheckMark />
			</div>
			<p className="textSecondary">{text}</p>
		</div>
	);
};

export default Option;
