import React from 'react';

import {Footer, Header} from 'components';

import {
	AccessSection,
	AssetsSection,
	BrowserSection,
	NftSection,
	StartSection,
	StepsSection,
} from '../components';

// import classes from './styles.module.scss';
import {Props} from './type';

const Home = ({privacyModal, tosModal}: Props) => {
	return (
		<>
			<Header />
			<StartSection />
			<AccessSection />
			<AssetsSection />
			{/* <StakingSection /> */}
			<NftSection />
			<BrowserSection />
			<StepsSection />
			<Footer privacyModal={privacyModal} tosModal={tosModal} />
		</>
	);
};

export default Home;
