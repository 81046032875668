import React from 'react';

import {ReactComponent as Arrow} from 'assets/svg/arrowLink.svg';
import classNames from 'classnames';

import classes from './styles.module.scss';

type Props = {
	text: string;
	link: string;
};

const ExtLink: React.FC<Props> = ({text, link}) => {
	const linkClasses = () =>
		classNames({
			[classes.link]: true,
			text,
		});

	return (
		<a href={link} className={linkClasses()}>
			<span>{text}</span>
			<Arrow />
		</a>
	);
};

export default ExtLink;
