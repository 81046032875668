import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {AllSwaps, Home} from 'pages';

import './styles/main.scss';

const App = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/privacy" element={<Home privacyModal />} />
				<Route path="/tos" element={<Home tosModal />} />
				<Route path="/all-swaps" element={<AllSwaps />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
