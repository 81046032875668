import React from 'react';

import {ButtonGradient, Option} from 'components';

import classes from './styles.module.scss';

const AccessSection = () => {
	return (
		<section id="access" className={classes.wrapper}>
			<div className={classes.container}>
				<div className={classes.content}>
					<div className={classes.sectionWrapper}>
						<h3 className="sectionHeading">
							You Deserve <span className="sectionHighlight">Easy Access </span>
							<span className="lineBreakBefore">to</span> Cryptocurrencies
						</h3>
					</div>
					<p className="textSecondary">QIE wallet is for you if you want to</p>
					<div className={classes.optionsWrapper}>
						<Option text="Buy QIE in under five minutes" />
						<Option text="Easily surf new QIE dApps" />
						<Option text="Track prices and charts within the app" />
						<Option text="View your collectibles, art, and NFTs all in one spot." />
						<Option text="Keep your crypto safe from hackers & scammers" />
					</div>
					<div className={classes.buttonWrapper}>
						<ButtonGradient text="Download Now" />
					</div>
				</div>
				<div className={classes.imgWrapper} />
			</div>
		</section>
	);
};

export default AccessSection;
