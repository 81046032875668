import React from 'react';

import classes from './styles.module.scss';

type Props = {
	text: string;
};

const ButtonGradient: React.FC<Props> = ({text}) => {
	return (
		<a className={classes.button} href="#download">
			{text}
		</a>
	);
};

export default ButtonGradient;
