import React, {FC} from 'react';
import ReactModal from 'react-modal';

import {ReactComponent as CrossIcon} from 'assets/svg/crossIcon.svg';

import classes from '../styles.module.scss';

type Props = {
	isOpen: boolean;
	onClick: () => void;
};

const Privacy: FC<Props> = ({isOpen, onClick}) => {
	return (
		<ReactModal
			isOpen={isOpen}
			contentLabel="Modal"
			overlayClassName={classes.overlayCustom}
			className={classes.contentCustom}
			onRequestClose={onClick}
			shouldCloseOnOverlayClick
			ariaHideApp={false}>
			<>
				<div className={classes.modalHeading}>
					<h2 className={classes.modalH2}>QIE Wallet Privacy and Policy</h2>
					<CrossIcon onClick={onClick} />
				</div>
				<div className={classes.modalContent}>
					<div>
						<p className={classes.modalText}>Last Updated: June 26, 2023</p>
						<p className={classes.modalText}>
							Please note that this privacy statement will regularly be updated to
							reflect any change in the way we handle your personal information or any
							changes in applicable laws.
						</p>
						<p className={classes.modalText}>
							This Privacy Notice describes the policies and procedures of DApps
							Platform, Inc., (“we,” “our,” or “us”) pertaining to the collection,
							use, and disclosure of your information on qiewallet.qiblockchain.online
							and related mobile applications and products we offer (the “Services” or
							“QIE Wallet”).
						</p>
						<p className={classes.modalText}>OVERVIEW</p>
						<p className={classes.modalText}>
							Your right to privacy and the protection of your personal data is
							important to us. The following sections provide further details as to
							how we process your personal information through QIE Wallet. We don’t
							share your information with third parties except to deliver you our
							Services and products, comply with the law, make QIE Wallet better,
							protect our rights, or effectuate a business transfer. We’re not a huge,
							faceless corporation. We’re just developers trying to deliver an
							incredible product. If you have any questions or concerns about this
							policy, please reach out to us at wallet.support@qiblockchain.online.
						</p>
						<p className={classes.modalText}>
							By using QIE Wallet, including downloading one of our mobile
							applications or visiting our website, you agree to the use, disclosure,
							and procedures outlined in this Privacy Policy.
						</p>
						<p className={classes.modalText}>
							WHAT PERSONAL INFORMATION DO WE COLLECT FROM OUR USERS?
						</p>
						<p className={classes.modalText}>
							We do our best to minimize the amount of Personal Information that we
							collect from QIE Wallet users. Your contact information, such as your
							phone number or email address (depending on how you contact us), may be
							collected when you communicate with us for support, or if you report a
							bug or other error related to QIE Wallet or when you interact with us
							through social media. When you use our Services, we process PUBLIC
							wallet addresses that you generate through QIE Wallet.
						</p>
						<p className={classes.modalText}>
							<strong>Category of personal information</strong>
						</p>
						<p className={classes.modalText}>
							<strong>Types of personal information captured by category</strong>
						</p>
						<p className={classes.modalText}>
							<strong>Purpose</strong>
						</p>
						<p className={classes.modalText}>
							<strong>Legal Basis (UK & GDPR)</strong>
						</p>
						<p className={classes.modalText}>
							<em>Personal details, contact details, and identifiers.</em>
						</p>
						<p className={classes.modalText}>
							Name and contact details (such as e-mail, phone numbers when you
							voluntarily have shared this information with us in order to be
							contacted)
						</p>
						<p className={classes.modalText}>
							Facilitating communication with you for customer support purposes. Our
							legitimate interests in ensuring proper communication and handling
							within the organization. Your consent when we ask for it to process your
							personal information for a specific purpose that we communicate to you.
							When you consent to processing your personal information for a specified
							purpose, you may withdraw your consent at any time and we will stop
							processing your data for that purpose.
						</p>
						<p className={classes.modalText}>
							<strong>
								System and application access data and internet and electronic
								network activity information
							</strong>
						</p>
						<p className={classes.modalText}>
							The Internet protocol (IP) address used to connect your computer or
							mobile device to the Internet. QIE Wallet Services metrics (e.g., the
							occurrences of technical errors, your interactions with service features
							and content and your setting preferences) Records of any contractual
							agreements between yourself and QIE Wallet
						</p>
						<p className={classes.modalText}>
							Provide troubleshooting, and improve our Wallet Services. To analyse
							performance, fix errors, and improve the usability and effectiveness of
							QIE Wallet services. Necessary for the performance of a contract to
							which you are a party.
						</p>
						<p className={classes.modalText}>
							Our legitimate interests and the interests of our users in, for example,
							detecting and preventing fraud and abuse in order to protect the
							security of our users, ourselves, or others. Our legitimate interests in
							keeping records and necessary for the performance of your contract.
						</p>
						<p className={classes.modalText}>
							<strong>WILL WE SHARE YOUR PERSONAL DATA WITH THIRD PARTIES?</strong>
						</p>
						<p className={classes.modalText}>
							Information about our users is an important part of our business and we
							are not in the business of selling our users’ personal information to
							others. We may transfer personal data to our service providers or third
							parties in connection with QIE Wallet’s operation of its business, as
							certain features on QIE Wallet rely on various third-party products and
							services (collectively “Third Party Services”). These third-party
							services providers only have access to certain Personal Information,
							such as your public Wallet addresses, to perform their functions and may
							not use it for other purposes. Furthermore, they must process the
							personal information in accordance with our contractual agreements and
							only as permitted by applicable data protections laws. Business
							transfers: As we continue to develop our business, we might sell or buy
							other businesses or services. In such transactions, user information
							generally is one of the transferred business assets but remains subject
							to the promises made in any pre-existing Privacy notice (unless, of
							course, the user consents otherwise). Also, in the unlikely event that
							QIE Wallet or substantially all of its assets are acquired, users'
							information will be one of the transferred assets. We may share
							non-personally identifiable information publicly and with our partners-
							like publishers, advertisers, developers, or right holders.
						</p>
						<p className={classes.modalText}>HOW WE USE THE INFORMATION WE GATHER</p>{' '}
						<p className={classes.modalText}>
							We primarily use the limited information that we collect to enhance QIE
							Wallet. Except if we sell all or a portion of our business, or as
							otherwise described below, we do not rent, trade, or sell your Personal
							Information.
						</p>
						<p className={classes.modalText}>
							Some ways we may use your Personal Information are to:
						</p>
						<p className={classes.modalText}>Contact you when necessary;</p>
						<p className={classes.modalText}>
							Respond to your comments, questions, or issues related to bugs or errors
							with QIE Wallet;
						</p>
						<p className={classes.modalText}>
							Provide you with additional information;
						</p>
						<p className={classes.modalText}>
							Send you information and marketing materials about services and products
							available through QIE Wallet, using push notifications or other means;
						</p>
						<p className={classes.modalText}>Train our team members;</p>
						<p className={classes.modalText}>Other internal business purposes.</p>
						<p className={classes.modalText}>
							Aggregated Personal Data and Non-Personal Information
						</p>
						<p className={classes.modalText}>
							We may share or disclose aggregated Personal Data or Non-Personal
							Information with service providers or with other persons we conduct
							business with, including but not limited potential third-parties for the
							purpose of showcasing the performance of the company.
						</p>
						<p className={classes.modalText}>
							These service providers and other persons may also share with us
							aggregated Non-Personal Information that they have independently
							developed or acquired. Additionally, we may combine aggregate
							information from the pixel tags, and web beacons, with similar data we
							collect from other visitors to help us improve our Services. When doing
							so, we do our best to ensure that aggregated information cannot be
							linked back to you.
						</p>
						<p className={classes.modalText}>Agents or Third-Party Partners</p>
						<p className={classes.modalText}>
							We may provide your Personal Information to our employees, contractors,
							agents, service providers, and designees (“Agents”) to enable them to
							perform certain services for us, including improvement of
							website-related services and features, and performance of maintenance
							services.
						</p>
						<p className={classes.modalText}>Business Transfers</p>
						<p className={classes.modalText}>
							We may choose to buy or sell assets. In these types of transactions,
							customer information is typically one of the business assets that would
							be transferred. Also, if we (or our assets) are acquired, or if we go
							out of business, enter bankruptcy, or go through some other change of
							control, your Personal Information could be one of the assets
							transferred to or acquired by a third party. By accepting this Privacy
							Notice, as outlined above, you consent to any such transfer.
						</p>
						<p className={classes.modalText}>Protection of Us and Others</p>
						<p className={classes.modalText}>
							We will share personal information outside of QIE Wallet if we have a
							reasonable belief that access, use, preservation, or disclosure of the
							information is reasonably necessary to comply with any applicable law,
							regulation, legal process, or enforceable governmental request; to
							cooperate with law enforcement; to enforce or apply our Terms of Service
							and other agreements; or to protect the rights, property, or safety of
							QIE Wallet, our employees, our users, or others.
						</p>
						<p className={classes.modalText}>
							YOUR RIGHTS WITH RESPECT TO THE PROCESSING OF PERSONAL DATA
						</p>
						<p className={classes.modalText}>
							You are entitled (under the conditions, and subject to the exceptions,
							set out in applicable law) to: Request Access to the personal
							information we process about you: You can request access to the
							information we have collected from you. You can do this by contacting us
							at wallet.support@qiblockchain.online. We will provide you with a copy
							of the data we process about you. To comply with your request, we may
							ask you to verify your identity. We will fulfill your request by sending
							your copy electronically. For any subsequent access request, we may
							charge you an administrative fee. Request a rectification of your
							personal data: If you believe that the information we have collected is
							incorrect or incomplete, you may contact us so we can update it and keep
							your data accurate. Object to the processing of your personal data: you
							may request that we no longer process your personal data. Request to
							erase your personal data: you may request the erasure of your personal
							data, including where such personal data would no longer be necessary to
							achieve the purposes for which it was collected. Any data that is no
							longer needed for purposes specified in the “How We Use the Information
							We Gather” section will be deleted after ninety (90) days. Wallet
							addresses created through the QIE Wallet application cannot be deleted
							from the Ethereum blockchain, therefore we are unable to delete this
							personal information. If at any point you wish for QIE Wallet to delete
							information about you, you may contact us at
							wallet.support@qiblockchain.online. Request the restriction of the
							processing of your personal data: you may request that QIEWallet only
							processes your personal data in limited circumstances, including with
							your consent. Under California Consumer Protection Act (“CCPA”) you also
							have the right not to be discriminated against because of the exercise
							of your rights. Please note that we do not sell your personal data.
						</p>
						<p className={classes.modalText}>DATA RETENTION</p>
						<p className={classes.modalText}>
							Please note that even If you delete your Wallet or addresses from the
							QIE Wallet mobile application, uninstall QIE Wallet mobile applications
							from your device, or request that your information be deleted, we still
							may retain some information that you have provided to us to maintain QIE
							Wallet or to comply with the laws and regulations to which QIE Wallet is
							subject. If you have any question or objection as to how we collect and
							process your personal information, please contact
							wallet.support@qiblockchain.online.
						</p>{' '}
						<p className={classes.modalText}>DATA SECURITY</p>
						<p className={classes.modalText}>
							We are committed to making sure your information is protected in
							accordance with applicable laws and our data privacy policies. We have
							selected third-party vendors that use the QI Blockchain network,
							including HovR, Qidex, and Pawsome, that help us keep your Personal
							Information safe. Unfortunately, we do not control these third parties
							and therefore cannot guarantee complete security. We work to protect the
							security of your personal information during transmission by using
							encryption protocols and software. We maintain physical, electronic and
							procedural safeguards in connection with the collection, storage and
							disclosure of your personal information and secure all connections with
							industry standard transport layer security. Even with all these
							precautions, we cannot fully guarantee against the access, disclosure,
							alteration, or deletion of data through events, including but not
							limited to hardware or software failure or unauthorized use. Any
							information that you provide to us is done so entirely at your own risk.
						</p>
						<p className={classes.modalText}>CHILDREN</p>
						<p className={classes.modalText}>
							We are especially sensitive about children’s information. Our Services
							are not targeted towards children, and our users must be at least
							eighteen (18) years old to use our services. We don’t knowingly collect
							information from children under the age of 13. If you are a parent or
							legal guardian of a minor child, we will treat any information that you
							provide us while using QIE Wallet on behalf of your minor child as
							Personal Information as otherwise provided in this Privacy Policy. If
							you have questions concerning our information practices with respect to
							children, or if you learn that a child under the age of 13 has used QIE
							Wallet, created a user account, or provided us with personal
							information, please email us at wallet.support@qiblockchain.online
						</p>
						<p className={classes.modalText}>
							CONDITIONS OF USE, NOTICES, CHANGES AND UPDATES TO PRIVACY NOTICE
						</p>
						<p className={classes.modalText}>
							If you choose to use QIE Wallet, your use and any dispute over privacy
							is subject to this Notice and our Terms of Service. If you have any
							concerns about privacy at QIE Wallet, please contact us with a complete
							description, and we will try to resolve it. You also have the right to
							contact your local Data Protection Authority. We reserve the right to
							update and revise this Privacy Policy at any time. We occasionally
							review this Privacy Notice to make sure it complies with applicable laws
							and conforms to changes in our business. If we do revise this Privacy
							Notice, we will update the “Effective Date” at the top of this page so
							that you can tell if it has changed since your last visit and will do
							our best to notify you. Please review this Privacy Notice regularly to
							ensure that you are aware of its terms. Any use of QIE Wallet after an
							amendment to our Privacy Notice constitutes your acceptance to the
							revised or amended terms.
						</p>
						<p className={classes.modalText}>QUESTIONS</p>
						<p className={classes.modalText}>
							We’d be happy to answer them. Shoot us an email or send us a note:
						</p>
						<p className={classes.modalText}>
							Email: wallet.support@qiblockchain.online
						</p>
						<p className={classes.modalText}>Thanks for reading our Privacy Policy!</p>
					</div>
				</div>
				<div className={classes.modalButtons}>
					<button type="button" className={classes.modalAccept} onClick={onClick}>
						Accept
					</button>
					<button type="button" className={classes.modalCancel} onClick={onClick}>
						Cancel
					</button>
				</div>
			</>
		</ReactModal>
	);
};

export default Privacy;
