const HEADER_LINKS = [
	{
		id: 0,
		text: 'Easy Access',
		link: '#access',
	},
	{
		id: 1,
		text: 'Assets',
		link: '#assets',
	},
	{
		id: 2,
		text: 'NFTs',
		link: '#nft',
	},
	{
		id: 3,
		text: 'Browser',
		link: '#browser',
	},
];

export default HEADER_LINKS;
