import React from 'react';

import {Footer, Header} from 'components';

import Table from './components/Table';

const AllSwaps = () => {
	return (
		<>
			<Header />
			<Table />
			<Footer />
		</>
	);
};

export default AllSwaps;
