import React from 'react';

import {ReactComponent as AppStore} from 'assets/svg/appStore.svg';
import {ReactComponent as ArrowRight} from 'assets/svg/arrowRight.svg';
import {ReactComponent as GooglePlay} from 'assets/svg/googlePlay.svg';
import {Step} from 'components';

import classes from './styles.module.scss';

const StepsSection = () => {
	return (
		<section id="download" className={classes.wrapper}>
			<div className={classes.headingWrapper}>
				<h3 className="sectionHeading">
					Get started in <span className="sectionHighlight">3 simple</span> steps
				</h3>
				<p className="textSecondary">It only takes a few minutes</p>
			</div>
			<div className={classes.stepsWrapper}>
				<Step
					number={1}
					text="Download
QIE wallet"
				/>
				<ArrowRight />
				<Step number={2} text="Deposit or buy crypto" />
				<ArrowRight />
				<Step number={3} text="Dive into QIE ecosystem" />
			</div>
			<div className={classes.storesWrapper}>
				<a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer">
					<AppStore />
				</a>
				<a
					href="https://play.google.com/store/apps/details?id=com.qiewallet"
					target="_blank"
					rel="noreferrer">
					<GooglePlay />
				</a>
			</div>
		</section>
	);
};

export default StepsSection;
