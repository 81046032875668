import React from 'react';

import classes from './styles.module.scss';

const Table = () => {
	return (
		<div className={classes.wrapper}>
			<h2 className={classes.heading}>Swaps History</h2>
			<table className={classes.table}>
				<thead>
					<tr>
						<th className={classes.column}>
							<div className={classes.columnHeadWrapper}>
								<div className={classes.columnHead}>ВАЛЮТА</div>
							</div>
						</th>
						<th className={classes.column}>
							<div className={classes.columnHeadWrapper}>
								<div className={classes.columnHead}>ВАЛЮТА</div>
							</div>
						</th>
						<th className={classes.column}>
							<div className={classes.columnHeadWrapper}>
								<div className={classes.columnHead}>ТХИД</div>
							</div>
						</th>
						<th className={classes.column}>
							<div className={classes.columnHeadWrapper}>
								<div className={classes.columnHead}>ДАТА</div>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<div>
								<div className={classes.value}>250 QIDEX</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>20 QIE</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>0x3e2...790b</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>2023-05-27T12:16:30+00:00</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div>
								<div className={classes.value}>250 QIDEX</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>20 QIE</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>0x3e2...790b</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>2023-05-27T12:16:30+00:00</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div>
								<div className={classes.value}>250 QIDEX</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>20 QIE</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>0x3e2...790b</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>2023-05-27T12:16:30+00:00</div>
							</div>
						</td>
					</tr>
					<tr>
						<td>
							<div>
								<div className={classes.value}>250 QIDEX</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>20 QIE</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>0x3e2...790b</div>
							</div>
						</td>
						<td>
							<div>
								<div className={classes.value}>2023-05-27T12:16:30+00:00</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default Table;
