import React from 'react';

import {ButtonGradient, Option} from 'components';

import classes from './styles.module.scss';

const BrowserSection = () => {
	return (
		<section id="browser" className={classes.wrapper}>
			<div className={classes.container}>
				<div className={classes.imgWrapper} />
				<div className={classes.content}>
					<h3 className="sectionHeading">
						Qi DApp <span className="sectionHighlight">Browser</span>
						<small> (coming soon)</small>
					</h3>
					<div className={classes.optionsWrapper}>
						<Option text="An all-inclusive Web3 browser designed to facilitate engagement with any decentralized application (DApp)." />
						<Option text="An attentively engineered tool that ensures an effortless, uncomplicated, and safe interface between you and any decentralized application (DApp) on QIE blockchain" />
						<Option text="A unified interface that's completely optimized for mobile use, allowing you to experience content specifically tailored for your device." />
					</div>
					<div className={classes.buttonWrapper}>
						<ButtonGradient text="Download Now" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default BrowserSection;
