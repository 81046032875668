import React from 'react';

import {ReactComponent as Caret} from 'assets/svg/caret.svg';
import {Option} from 'components';

import classes from './styles.module.scss';

const AssetsSection = () => {
	return (
		<section id="assets" className={classes.wrapper}>
			<div className={classes.container}>
				<div className={classes.imgWrapper} />
				<div className={classes.content}>
					<div className={classes.sectionWrapper}>
						<h3 className="sectionHeading">
							<span className={classes.lineBreak}>Every </span>
							<span className="sectionHighlight">QIE-20 </span>
							token supported
						</h3>
					</div>
					<p className="textSecondary">QIE wallet is for you if you want to</p>
					<div className={classes.optionsWrapper}>
						<Option text="Get & hold QIE and many QIE-20 tokens" />
						<Option text="Ensure privacy & security when storing your crypto." />
						<Option text="Buy more crypto with your credit card." />
					</div>
					<div className={classes.gradient}>
						<div>
							<Caret />
						</div>
						<div className={classes.captionWrapper}>
							<p className={classes.heading}>Fully QIE-20 compatible</p>
							<p className={classes.text}>
								Here you can send & receive every QIE-20 token
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default AssetsSection;
