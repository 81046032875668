import React from 'react';

import {ButtonGradient} from 'components';

import classes from './styles.module.scss';

const NftSection = () => {
	return (
		<section id="nft" className={classes.wrapper}>
			<div className={classes.container}>
				<div className={classes.contentWrapper}>
					<div className={classes.downloadWrapper}>
						<h3 className="sectionHeading">
							All NFTs in <span className="sectionHighlight">One Place</span>
							<span className="lineBreakBefore"> with QIE wallet</span>
						</h3>
						<p className="textSecondary">
							QIE Wallet offers a straightforward avenue for storing all your unique
							QIE721crypto NFTs and gaming assets together. Fond of your crypto NFTs?
							We hold the same affection for them. Keep all your cherished NFTs secure
							in your QIE Wallet.
						</p>
						<div className={classes.buttonWrapper}>
							<ButtonGradient text="Download Now" />
						</div>
					</div>
					<div className={classes.explanationWrapper}>
						<p className="textHeading">What are NFTs?</p>
						<p className="textSecondary">
							NFTs, or non-fungible tokens, are unique digital assets. They are mainly
							mainly recognized as ERC721 and ERC1155 on Ethereum, and as BEP721 and
							on BSC, known as NFTs. These assets are either singular in nature or
							restricted in quantity. Think of them as akin to a renowned Monet
							painting or a rare video game like Final Fantasy VII for the
							PlayStation. Typically, crypto collectibles emulate real-world entities,
							such as pets or avatars. Some, however, are computer-generated artworks.
							Each token possesses unique characteristics, and there are constraints
							on the total number of tokens that can be created.
						</p>
					</div>
				</div>
				<div className={classes.imgWrapper} />
			</div>
		</section>
	);
};

export default NftSection;
