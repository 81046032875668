import React, {useState} from 'react';
import {slide as Menu} from 'react-burger-menu';
// eslint-disable-next-line import/no-extraneous-dependencies
import {useNavigate} from 'react-router-dom';

import {ReactComponent as AppStore} from 'assets/svg/appStore.svg';
import {ReactComponent as BurgerIcon} from 'assets/svg/burgerIcon.svg';
import {ReactComponent as CrossIcon} from 'assets/svg/crossIcon.svg';
import {ReactComponent as GooglePlay} from 'assets/svg/googlePlay.svg';
import {ReactComponent as Logo} from 'assets/svg/logo.svg';
import HEADER_LINKS from 'const';

import ButtonGradient from '../ButtonGradient';
import ButtonSolid from '../ButtonSolid';
import ExtLink from '../ExtLink';
import LinkTo from '../LinkTo';

import classes from './styles.module.scss';

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);

	const navigate = useNavigate();

	const onClick = (link: string) => {
		setIsOpen(!isOpen);
		navigate(link);
	};

	return (
		<header className={classes.wrapper}>
			<div className={classes.logoWrapper}>
				<div className={classes.burgerWrapper}>
					<Menu
						isOpen={isOpen}
						onStateChange={state => setIsOpen(state.isOpen)}
						customBurgerIcon={<BurgerIcon />}
						customCrossIcon={<CrossIcon />}>
						<div className={classes.burgerLinks}>
							{HEADER_LINKS.map(elem => (
								<button
									type="button"
									key={`key_${elem.id}`}
									onClick={() => onClick(elem.link)}>
									<LinkTo text={elem.text} link={elem.link} />
								</button>
							))}
							<ExtLink text="Link" link="#" />
							<ButtonSolid text="En" />
							<div className={classes.burgerStores}>
								<a
									href="https://www.apple.com/app-store/"
									target="_blank"
									rel="noreferrer">
									<AppStore />
								</a>
								<a
									href="https://play.google.com/store/apps/details?id=com.qiewallet"
									target="_blank"
									rel="noreferrer">
									<GooglePlay />
								</a>
							</div>
						</div>
					</Menu>
				</div>
				<Logo className={classes.logo} />
			</div>
			<nav className={classes.nav}>
				<ul className={classes.list}>
					{HEADER_LINKS.map(elem => (
						<li key={elem.id}>
							<LinkTo text={elem.text} link={elem.link} />
						</li>
					))}
				</ul>
			</nav>
			<div className={classes.buttons}>
				<div className={classes.langButton}>
					<ButtonSolid text="En" />
				</div>
				<div className={classes.startedButton}>
					<ButtonGradient text="Get started" />
				</div>
			</div>
		</header>
	);
};

export default Header;
