import React, {useEffect} from 'react';

import {ReactComponent as DiscordIcon} from 'assets/svg/discordIcon.svg';
import {ReactComponent as FacebookIcon} from 'assets/svg/facebookIcon.svg';
import {ReactComponent as GithubIcon} from 'assets/svg/githubIcon.svg';
import {ReactComponent as InstagramIcon} from 'assets/svg/instagramIcon.svg';
import {ReactComponent as Logo} from 'assets/svg/logoGrey.svg';
import {ReactComponent as RedditIcon} from 'assets/svg/redditIcon.svg';
import {ReactComponent as TelegramIcon} from 'assets/svg/telegramIcon.svg';
import {ReactComponent as TwitterIcon} from 'assets/svg/twitterIcon.svg';
import {ReactComponent as WeiboIcon} from 'assets/svg/weiboIcon.svg';

import HEADER_LINKS from '../../const';
import ExtLink from '../ExtLink';
import LinkTo from '../LinkTo';

import Privacy from './Privacy';
import Terms from './Terms';
import {Props} from './type';

import classes from './styles.module.scss';

const Footer = ({privacyModal, tosModal}: Props) => {
	const [isPrivacyOpen, setIsPrivacyOpen] = React.useState(false);
	const [isTosOpen, setIsTosOpen] = React.useState(false);

	useEffect(() => {
		if (privacyModal) {
			setIsPrivacyOpen(true);
		}
	}, [privacyModal]);

	useEffect(() => {
		if (tosModal) {
			setIsTosOpen(true);
		}
	}, [tosModal]);

	const onPrivacyClick = () => {
		if (isPrivacyOpen) {
			setIsPrivacyOpen(false);
			window.history.replaceState(null, 'QI Blockchain', '/');
		} else {
			setIsPrivacyOpen(true);
			window.history.replaceState(null, 'QI Blockchain', '/#privacy');
		}
	};
	const onTosClick = () => {
		if (isTosOpen) {
			setIsTosOpen(false);
			window.history.replaceState(null, 'QI Blockchain', '/');
		} else {
			setIsTosOpen(true);
			window.history.replaceState(null, 'QI Blockchain', '/#tos');
		}
	};

	return (
		<footer id="footer" className={classes.wrapper}>
			<div className={classes.block}>
				<div className={classes.logoWrapper}>
					<Logo />
				</div>
				<div className={classes.socialWrapper}>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="https://github.com/qi-Blockchain" target="_blank" rel="noreferrer">
						<GithubIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="#" target="_blank" rel="noreferrer">
						<FacebookIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="https://t.me/HovRonQiblockchain" target="_blank" rel="noreferrer">
						<TelegramIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="https://twitter.com/qiblockchain" target="_blank" rel="noreferrer">
						<TwitterIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="https://discord.gg/9HCNTyqkwa" target="_blank" rel="noreferrer">
						<DiscordIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						href="https://www.reddit.com/r/qiblockchain/"
						target="_blank"
						rel="noreferrer">
						<RedditIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a href="https://weibo.com/pawsome" target="_blank" rel="noreferrer">
						<WeiboIcon />
					</a>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						href="https://www.instagram.com/qiblockchain.online"
						target="_blank"
						rel="noreferrer">
						<InstagramIcon />
					</a>
				</div>
				<button type="button" className={classes.link} onClick={onPrivacyClick}>
					Privacy Policy
				</button>
				<button type="button" className={classes.link} onClick={onTosClick}>
					Terms Of Service
				</button>
			</div>
			<div className={classes.block}>
				<p className={classes.blockHeading}>WALLET</p>
				<div className={classes.block}>
					{HEADER_LINKS.map(elem => (
						<div key={`key_${elem.id}`}>
							<LinkTo text={elem.text} link={elem.link} />
						</div>
					))}
				</div>
			</div>
			{/* <div className={classes.block}> */}
			{/*	<p className={classes.blockHeading}>INFORMATION</p> */}
			{/*	<div className={classes.block}> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Link name" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Link name" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Link name" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Link name" link="#" /> */}
			{/*	</div> */}
			{/* </div> */}
			<div className={classes.block}>
				<p className={classes.blockHeading}>USE CRYPTO</p>
				<div className={classes.block}>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<ExtLink text="QIE Blockchain Website" link="https://qiblockchain.online" />
					<ExtLink
						text="Buy QIE with a credit card"
						link="https://track.swipelux.com/?api-key=0a2236fc-af87-4939-bb34-9eb3f0280e77"
					/>
					<ExtLink text="QIDEX Decentralized exchange" link="https://qidex.site" />
				</div>
			</div>
			{/* <div className={classes.block}> */}
			{/*	<p className={classes.blockHeading}>ASSETS</p> */}
			{/*	<div className={classes.block}> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Bitcoin (BTC)" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Bitcoin (BTC)" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Bitcoin (BTC)" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Bitcoin (BTC)" link="#" /> */}
			{/*		/!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
			{/*		<ExtLink text="Bitcoin (BTC)" link="#" /> */}
			{/*	</div> */}
			{/* </div> */}
			<div className={classes.termsWrapper}>
				<button type="button" className={classes.link} onClick={onPrivacyClick}>
					Privacy Policy
				</button>
				<button type="button" className={classes.link} onClick={onTosClick}>
					Terms Of Service
				</button>
			</div>
			<Terms isOpen={isTosOpen} onClick={onTosClick} />
			<Privacy isOpen={isPrivacyOpen} onClick={onPrivacyClick} />
		</footer>
	);
};

export default Footer;
