import React from 'react';

import classes from './styles.module.scss';

type Props = {
	number: number;
	text: string;
};

const Step: React.FC<Props> = ({number, text}) => {
	return (
		<div className={classes.wrapper}>
			<div className={classes.number}>{number}</div>
			<p className={classes.text}>{text}</p>
		</div>
	);
};

export default Step;
