import React from 'react';

import classNames from 'classnames';

import classes from './styles.module.scss';

type Props = {
	text: string;
};

const ButtonSolid: React.FC<Props> = ({text}) => {
	const buttonClasses = () =>
		classNames({
			[classes.button]: true,
			text,
		});

	return (
		<button type="button" className={buttonClasses()}>
			{text}
		</button>
	);
};

export default ButtonSolid;
