import React from 'react';

import classes from './styles.module.scss';

type Props = {
	icon: any;
	title: string;
	text: string;
};

const FeatureCard: React.FC<Props> = ({icon, title, text}) => {
	return (
		<div className={classes.wrapper}>
			{icon}
			<div className={classes.textWrapper}>
				<p className={classes.title}>{title}</p>
				<p className={classes.text}>{text}</p>
			</div>
		</div>
	);
};

export default FeatureCard;
