import React from 'react';

import {ReactComponent as AppStore} from 'assets/svg/appStore.svg';
import {ReactComponent as CardIcon} from 'assets/svg/card.svg';
import {ReactComponent as ExchangeIcon} from 'assets/svg/exchange.svg';
import {ReactComponent as GooglePlay} from 'assets/svg/googlePlay.svg';
import {ReactComponent as SecurityIcon} from 'assets/svg/security.svg';

import FeatureCard from '../FeatureCard';

import classes from './styles.module.scss';

const StartSection = () => {
	return (
		<section className={classes.wrapper}>
			<div className={classes.container}>
				<div className={classes.content}>
					<h2 className={classes.heading}>
						<span className={classes.line}>The most</span>
						<span className={classes.line}>reliable & secure</span>
						crypto wallet
					</h2>
					<p className="textSecondary">
						Buy, store, collect NFTs, exchange & earn crypto. using QIE Wallet.
					</p>
					<div className={classes.stores}>
						<a href="https://www.apple.com/app-store/" target="_blank" rel="noreferrer">
							<AppStore />
						</a>
						<a
							href="https://play.google.com/store/apps/details?id=com.qiewallet"
							target="_blank"
							rel="noreferrer">
							<GooglePlay />
						</a>
					</div>
				</div>
				<div className={classes.illustration}>
					<div className={classes.cardsWrapper}>
						<div className={classes.buyCard}>
							<FeatureCard
								icon={<CardIcon />}
								title="Buy Crypto With a Card"
								text="Get your first $50 of QIE."
							/>
						</div>
						<div className={classes.exchangeCard}>
							<FeatureCard
								icon={<ExchangeIcon />}
								title="Exchange Instantly"
								text="No forms, no selfies. Trade crypto anytime with ease."
							/>
						</div>
						<div className={classes.securityCard}>
							<FeatureCard
								icon={<SecurityIcon />}
								title="Private & Secure"
								text="Only you can access your wallet."
							/>
						</div>
					</div>
				</div>
			</div>
			<div className={classes.cardsMobile}>
				<FeatureCard
					icon={<CardIcon />}
					title="Buy Crypto With a Card"
					text="Get your first $50 of QIE."
				/>
				<FeatureCard
					icon={<ExchangeIcon />}
					title="Exchange Instantly"
					text="No forms, no selfies. Trade crypto anytime with ease."
				/>
				<FeatureCard
					icon={<SecurityIcon />}
					title="Private & Secure"
					text="Only you can access your wallet."
				/>
			</div>
		</section>
	);
};

export default StartSection;
